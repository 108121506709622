import React, { useState, useEffect } from "react";
import "./DailyReports.scss";
//import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { format, utcToZonedTime } from "date-fns-tz";
import { toast } from "react-toastify";
import moment from "moment-timezone"
import * as Types from "../POSWindow/POSTypes";
import { copyFile } from "fs";



interface Product {
	id: string;
	sku: string;
	product: {
		id: string;
		title: string;
	};
	inventoryQuantity: number;
	inventoryItem: {
		inventoryLevels: {
			edges: Array<{
				node: {
					id: string;
					available: number;
				}
			}>
		}
	};
}


interface TransactionItem {
	taxable: {
		priceSet: {
			shopMoney: { amount: number; };
		};
	}[];
	customer: any;
	taxExempt: unknown;
	amount?: number;
	gateway?: string;
	items?: any[]; // Define a more specific type here based on your actual data structure
	refundItems?: any[]; // Define a more specific type here
	fees?: number;
	taxTotal?: number;
	date?: string;
	paydate?: string;
	source?: 'online' | 'POS';
	title?: string;
	category?: string;
	vendor?: string;
	quantity?: number;
	taxed?: number;
	taxLines?: { priceSet: { shopMoney: { amount: number } } }[];
	price?: number;
	totalPrice?: number;
	POSrefundItems?: any[];
	POSrefundTransactions?: any[];
}



let processedLineItems: any[] = [];

export default function DailyReports() {
	const getTodaysDate = () => {
		const now = new Date();
		const chicagoTime = utcToZonedTime(now, "America/Chicago");

		const year = format(chicagoTime, "yy");
		const month = format(chicagoTime, "MM");
		const day = format(chicagoTime, "dd");

		return `${month}${day}${year}`;
	};

	const getTodaysDateValue = () => {
		const now = new Date();
		const chicagoTime = utcToZonedTime(now, "America/Chicago");

		const year = format(chicagoTime, "yyyy");
		const month = format(chicagoTime, "MM");
		const day = format(chicagoTime, "dd");

		return `${year}-${month}-${day}`;
	};

	const [todayDate, setTodayDate] = useState(getTodaysDate());
	const [startDate, setStartDate] = useState<string | undefined>(undefined);
	const [startDateRegister, setStartDateRegister] = useState<string | undefined>(undefined);
	const [endDateRegister, setEndDateRegister] = useState<string | undefined>(undefined);
	const [endDate, setEndDate] = useState<string | undefined>(undefined);
	const [todaysDateValue, setTodaysDateValue] = useState(getTodaysDateValue());
	const [transactionArray, setTransactionArray] = useState<TransactionItem[]>([]);
	//const [transactionArray2,setTransactionArray2] = useState([]);
	const [partialTransactionArray, setPartialTransactionArray] = useState<TransactionItem[]>([]);
	const [procTotals, setProcTotals] = useState<any[]>([[], [], []]);
	const [procTotal, setProcTotal] = useState(0);
	const [transactionCursor, setTransactionCursor] = useState("");
	const [countCursor, setCountCursor] = useState("");
	const [anotherPage, setAnotherPage] = useState(false);
	const [anotherCountPage, setAnotherCountPage] = useState(0);
	const [statusMessage, setStatusMessage] = useState("");
	const [triggerAnotherPage, setTriggerAnotherPage] = useState(0);
	const [triggerAnotherPOSPage, setTriggerAnotherPOSPage] = useState(0);
	const [triggerAnotherCountPage, setTriggerAnotherCountPage] = useState(0);
	const [tenderCount, setTenderCount] = useState(0);
	const [transactions, setTransactions] = useState([]);
	const [orderIds, setOrderIds] = useState([]);
	const [tenderDone, setTenderDone] = useState(false);
	const [waiting, setWaiting] = useState(false);
	const [timeEst, setTimeEst] = useState(0);
	const resultCount = 250;
	const [triggerDateChange, setTriggerDateChange] = useState(0);
	const [triggerAbort, setTriggerAbort] = useState(false);
	//const [stripeTotal,setStripeTotal]=useState(0);
	//const [shopifyTotal,setShopifyTotal]=useState(0);
	//const [stripeFeeTotal,setStripeFeeTotal]=useState(0);
	//const [shopifyFeeTotal,setShopifyFeeTotal]=useState(0);
	//const [passTotal,setPassTotal]=useState(0);
	//const [memTotal,setMemTotal]=useState(0);
	//const [gcTotal,setGcTotal]=useState(0);
	//const [tfTotal,setTfTotal]=useState(0);
	const [itemList, setItemList] = useState<any[]>([]);
	const [attendanceCount, setAttendanceCount] = useState<number | null>(null);
	const [onlineAttendanceCount, setOnlineAttendanceCount] = useState<number | null>(null);
	const [POSattendanceCount, setPOSattendanceCount] = useState<number | null>(null);
	const [isCopying, setIsCopying] = useState(false);
	const [products, setProducts] = useState<Product[]>([]);
	//const [todayInventory,setTodayInventory] = useState(0);
	const [updatedInventory, setUpdatedInventory] = useState({});
	const [memberCheckins, setMemberCheckins] = useState(0);
	const [BFFCheckins, setBFFCheckins] = useState(0);
	const [gcRedeems, setGcRedeems] = useState(0);
	const [donationRedeems, setDonationRedeems] = useState(0);
	const [totalTaxFree, setTotalTaxFree] = useState(0);
	const [refreshAttendance, setRefreshAttendance] = useState(0);
	const [visitorCount, setVisitorCount] = useState<any>({
		"BFF": 0,
		"Gift Card": 0,
		"GoKarts": 0,
		"Group": 0,
		"Indoor": 0,
		"Last 3 Hours": 0,
		"Member": 0,
		"Online": 0,
		"Party": 0,
		"Switchback": 0,
		"Unlimited": 0,
		"Walk-In": 0,
	});
	const [calendarCount, setCalendarCount] = useState<any>({
		"Street": 0,
		"Online": 0,
		"Group": 0,
		"Member": 0,
		"Gift Card": 0,
	});

	const getHours = async () => {
		try {
			const res = await fetch(`/api/metafield/hours`, {
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
				},
			});
			const info = await res.json();
			const hrs = info.data.shop.metafields.edges[0].node.value;
			const hrsp = JSON.parse(hrs);
			return hrsp;
		} catch (error) {
			// Handle the error
			console.error(error);
		}
	};

	const dateFormatter = (d) => {
		d = new Date(d);
		let dd = d.getDate();
		if (dd < 10) {
			dd = "0" + dd;
		}
		let mm = d.getMonth() + 1; //January is 0!
		if (mm < 10) {
			mm = "0" + mm;
		}
		const yyyy = d.getFullYear();
		return yyyy + "-" + mm + "-" + dd;
	};

	const minusOneDay = (d) => {
		let timestamp = Date.parse(d);
		timestamp -= 1;
		return dateFormatter(timestamp);
	};

	const thisHours = (date, hrs) => {
		if (date && hrs) {
			let dy = date.split("-")[0].split("20")[1];
			dy = dy * 1;
			let dm = date.split("-")[1];
			dm = dm * 1 - 1;
			//let md = dm+1;
			let dd = date.split("-")[2];
			dd = dd * 1;
			const data = hrs[dy][dm][dd];
			return `${data}`;
		} else {
			return "could not check";
		}
	};

	useEffect(() => {
		//console.log('totalTaxFree updated',totalTaxFree)
	}, [totalTaxFree]);

	useEffect(() => {
		setProcTotals([[], [], []]);
		setProcTotal(0);
		setTransactionArray([]);
	}, [startDate, endDate]); //reset processor totals when dates change

	useEffect(() => {
		setAnotherPage(false);
		setTenderCount(0);
		setTransactions([]);
		processedLineItems = [];
		setTransactionArray([]);
		setTenderDone(false);
		setAnotherCountPage(0);
		//setStatusMessage(`Loading Data for transactions from ${startDate} to ${endDate}...`)
		//console.log('date changed')
		setPartialTransactionArray([]);
		setProcTotals([[], [], []]);
		setTriggerAnotherCountPage(prev => prev + 1);
		setTriggerAnotherPage(prev => prev + 1)
		setTotalTaxFree(0);
	}, [triggerDateChange]); //reset transaction arrays
	/*
		useEffect(() => {
			if (partialTransactionArray && tenderCount) {
				//console.log('tender count: ',tenderCount,'trans length: ',partialTransactionArray.length)
				const percentDone = Math.ceil(
					(partialTransactionArray.length / tenderCount) * 100
				);
				const fetchesRemaining = Math.ceil(
					(tenderCount - partialTransactionArray.length) / 5
				);
				const timeLeft =
					(fetchesRemaining * estimator[0] + estimator[3] - estimator[1]) /
					estimator[2];
				setStatusMessage(
					`${percentDone}% Done, about ${timeLeft.toFixed(
						0
					)} seconds remaining...`
				);
			} else {
				setStatusMessage("");
			}
		}, [tenderCount, partialTransactionArray]); // calculate time remaining when building report based on API respsonses
		
		useEffect(() => {
			setTenderCount(
				transactions.reduce((sum, innerArray) => sum + innerArray.length, 0)
			);
			//console.log("new tender count: ",transactions.reduce((sum, innerArray) => sum + innerArray.length, 0),transactions);
			//console.log("tender done?: ", tenderDone);
			if (tenderDone) {
				const orderids = [];
				for (let i = 0; i < transactions.length; i++) {
					orderids[i] = transactions[i]
						.map((transaction) => {
							// Extract the numerical ID from the full ID string
							const idMatch = transaction.node?.order?.id?.match(/(\d+)$/);
							return idMatch ? idMatch[0] : null;
						})
						.filter(Boolean); // Remove any null values
				}
				//console.log("order ids: ", orderids);
				setOrderIds(orderids);
				//setTriggerAnotherPage(triggerAnotherPage + 1);
			}
		}, [tenderDone]);
		
		useEffect(() => {
			if (startDate && endDate) {
				fetch(`/api/tender-count`, {
					method: "POST",
					body: JSON.stringify({
						startDate: startDate,
						endDate: endDate,
						num: 250,
						cursor: anotherCountPage ? countCursor : "",
					}),
					headers: {
						"Content-type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					},
				})
					.then((res) => res.json())
					.then((response) => {
						setTransactions((prevTransactions) => [
							...prevTransactions,
							[...response.data.tenderTransactions.edges],
						]);
						if (response.data.tenderTransactions.pageInfo.hasNextPage) {
							setAnotherCountPage(anotherCountPage + 1);
							setCountCursor(response.data.tenderTransactions.edges[249].cursor);
							setTimeout(() => {
								setTriggerAnotherCountPage(triggerAnotherCountPage + 1);
							}, 100);
						} else {
							setTenderDone(true);
						}
					})
					.catch((error) => {
						console.error("Error fetching transactions:", error);
					});
			}
		}, [triggerAnotherCountPage]); //find out how many transactions are in date range to estimate report build time
		*/
	useEffect(() => {
		//console.log('tender count: ',tenderCount)
		if (triggerAbort) {
			setWaiting(false);
			//setStatusMessage('')
			setTransactionCursor("");
			setTriggerAbort(false);
		} else if (startDate && endDate) {
			//console.log('time to fetch...')
			setWaiting(true);
			//console.log('end date: ', endDate, 'yesterday: ',todayDate,todaysDateValue);
			//is end date yesterday?

			fetch(`/api/tender-transactions`, {
				method: "POST",
				body: JSON.stringify({
					startDate: startDate,
					endDate: endDate,
					num: resultCount,
					cursor: anotherPage ? transactionCursor : "",
				}),
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
				},
			})
				.then((res) => res.json())
				.then((response) => {
					if (response.errors) {
						//console.log(response.errors[0].message)
						let errWaitTime = 0;
						if (response.extensions && response.extensions.cost) {
							const errCost = response.extensions.cost.requestedQueryCost;
							const errAvail =
								response.extensions.cost.throttleStatus.currentlyAvailable;
							const errRr = response.extensions.cost.throttleStatus.restoreRate;
							if (errCost > errAvail) {
								errWaitTime = ((errCost - errAvail) / errRr) * 1000;
							}
						}
						const newEmptyArray = [];
						return [newEmptyArray, transactionCursor, errWaitTime];
					} else {
						//console.log('response:',response)
						const res = response.data.tenderTransactions.edges;
						//console.log('res:',res)
						const newArray: any[] = [];

						for (let i = 0; i < res.length; i++) {
							if (!res[i]?.node?.order) {
								continue;
							}
							const amt = res[i].node.amount.amount;
							const date = res[i].node.processedAt;
							const taxExempt = res[i].node.order.customer?.taxExempt || res[i].node.order.taxExempt;
							const customer = res[i].node.order.customer;
							let transFees = 0;
							const taxLines = res[i]?.node?.order?.taxLines || [];
							let taxTotal = 0;
							taxLines.map((line) => {
								taxTotal += Number(line.priceSet.shopMoney.amount);
							});
							if (taxTotal > (amt / 1.0825) * 0.0825) {
								taxTotal = (amt / 1.0825) * 0.0825;
							}
							let gateway =
								res[i].node.order?.transactions[0]?.formattedGateway || "Other";
							let refundItems: any[] = [];
							//find the payout date by taking the transaction date, finding the next Monday deadline, and then adding two business days for Wednesday payout setting
							//needs to be modified if payout date or frequency changes
							//console.log(date)
							let tdate = new Date(Date.parse(date));
							//console.log(tdate)
							const timeZoneOffset = tdate.getTimezoneOffset();
							const utcTimestamp = tdate.getTime() + timeZoneOffset * 60 * 1000;
							let paydate: string | Date = new Date(utcTimestamp);
							tdate = new Date(utcTimestamp);
							//console.log(paydate)

							const daysUntilMonday = (1 + 7 - tdate.getDay()) % 7;
							const daysUntilWednesday = daysUntilMonday + 2;
							//console.log(daysUntilMonday)
							// add the number of days until the next Monday to the date object
							paydate.setDate(paydate.getDate() + daysUntilWednesday);
							const options: Intl.DateTimeFormatOptions = {
								day: "2-digit",
								month: "2-digit",
								year: "numeric",
							};
							paydate = paydate.toLocaleDateString("en-ca", options);
							//console.log(`date ${date} is resulting in tdate of ${tdate} and paydate of ${paydate}`)
							//console.log(`date ${paydate} + ${(1 + 7 - paydate.getDay()) % 7} -> ${paydate}`)
							let lineItems: any[] = [];
							if (amt > 0) {
								lineItems = res[i].node?.order?.lineItems?.edges || [];
								lineItems = lineItems.filter((item) => {
									//console.log("checking: ", item.node.id, processedLineItems);
									if (processedLineItems.includes(item.node.id)) {
										//console.log("already in set");
										return false; // Do not include this item in the new array
									} else {
										//console.log("adding to set: ", item.node.id);
										processedLineItems.push(item.node.id);
										return true; // Include this item in the new array
									}
								});

								if (gateway?.includes("Stripe")) {
									//console.log('fees:',res[i].node.order.transactions)
									transFees = parseFloat((amt * 0.029 + 0.3).toFixed(2));
								} else if (gateway?.includes("Manual")) {
									transFees = parseFloat((amt * 0.029 + 0.3).toFixed(2));
								} else {
									for (
										let j = 0;
										j < res[i]?.node?.order?.transactions?.length;
										j++
									) {
										if (
											amt ===
											res[i].node.order.transactions[j].amountSet.shopMoney
												.amount &&
											res[i].node.order.transactions[j].fees.length > 0
										) {
											//console.log('fees:',res[i].node.order.transactions[j].fees)
											transFees =
												res[i].node.order.transactions[j].fees[0].amount.amount;
										}
									}
								}
							}
							if (amt < 0) {
								const refunds = res[i].node.order.refunds;
								//taxTotal = 0;
								//console.log('found a refund!',refunds)
								for (let jj = 0; jj < refunds.length; jj++) {
									for (
										let k = 0;
										k < refunds[jj].transactions.edges.length;
										k++
									) {
										const transaction =
											0 -
											refunds[jj].transactions.edges[k].node.amountSet.shopMoney
												.amount;
										//console.log(`Does ${transaction} = ${amt}?`)
										if (transaction == amt) {
											refundItems = refunds[jj].refundLineItems.edges;
											refundItems = refundItems.filter((item) => {
												//console.log("checking: ", item.node.id,processedLineItems);
												if (processedLineItems.includes(item.node.id)) {
													//console.log('already in set')
													return false; // Do not include this item in the new array
												} else {
													//console.log('adding to set: ',item.node.id)
													processedLineItems.push(item.node.id);
													return true; // Include this item in the new array
												}
											});
											//console.log('yes!')
										} else {
											//console.log('no')
										}
									}
								}
								//console.log(refundItems)
							}
							if (gateway === "Manual") {
								gateway = "Stripe";
							}
							if (gateway === "Stripe" || gateway === "Shopify Payments") {
								newArray.push({
									amount: res[i].node.amount.amount,
									gateway: gateway,
									items: lineItems,
									refundItems: refundItems,
									fees: transFees,
									taxLines: taxLines,
									taxTotal: Number(taxTotal.toFixed(2)),
									date: date,
									paydate: paydate,
									source: 'online',
									taxExempt: taxExempt,
									customer: customer
								});
							}
							//console.log('new line: ',newArray[i]);
						}
						//console.log('newArray:',newArray)
						if (response.data.tenderTransactions.pageInfo.hasNextPage) {
							const cost = response.extensions.cost.actualQueryCost;
							const estcost = response.extensions.cost.requestedQueryCost;
							const avail =
								response.extensions.cost.throttleStatus.currentlyAvailable;
							const rr = response.extensions.cost.throttleStatus.restoreRate;
							let waitTime = 1;
							if (cost > avail) {
								waitTime = ((cost - avail) / rr) * 1000;
							}
							const cursor = res[resultCount - 1].cursor;
							//console.log('cursor:',cursor)
							//let hasNextPage = response.data.tenderTransactions.pageInfo.hasNextPage
							return [newArray, cursor, waitTime];
						} else {
							return [newArray, "0", false];
						}
					}
				})
				.then((response) => {
					if (response[2]) {
						setAnotherPage(true);
						//console.log('partArray: ',partialTransactionArray.concat(response[0]))
						setPartialTransactionArray(
							partialTransactionArray.concat(response[0])
						);
						setTransactionCursor(response[1]);

						setTimeEst(response[2]);
						setTimeout(function () {
							//console.log('okay, done waiting.')
							setTriggerAnotherPage(triggerAnotherPage + 1);
						}, response[2]);
					} else {
						setAnotherPage(false);
						//console.log('last page has been loaded!')
						//console.log('partArray: ',partialTransactionArray.concat(response[0]))
						if (triggerAbort) {
							setWaiting(false);
							setTriggerAbort(false);
							//setStatusMessage('')
							setTransactionCursor("");
						} else {
							//setTransactionArray(partialTransactionArray.concat(response[0]));
							setPartialTransactionArray(
								partialTransactionArray.concat(response[0])
							);
							//setStatusMessage('')
							setTransactionCursor("");
							//setWaiting(false);
							setTriggerAnotherPOSPage(triggerAnotherPOSPage + 1);
						}
					}
				});
		}
	}, [triggerAnotherPage]); //Get all transactions each time the start or end date changes and fill in transaction array

	useEffect(() => {
		//console.log('tender count: ',tenderCount)
		//console.log('POS Page Triggered', triggerAnotherPOSPage)
		if (triggerAbort) {
			setWaiting(false);
			//setStatusMessage('')
			setTransactionCursor("");
			setTriggerAbort(false);
		} else if (waiting) {
			//console.log('time to fetch...')
			//setWaiting(true);
			//console.log('end date: ', endDate, 'yesterday: ',todayDate,todaysDateValue);
			//is end date yesterday?

			fetch(`/api/tender-transactions`, {
				method: "POST",
				body: JSON.stringify({
					startDate: startDateRegister,
					endDate: endDateRegister,
					num: resultCount,
					cursor: anotherPage ? transactionCursor : "",
				}),
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
				},
			})
				.then((res) => res.json())
				.then((response) => {
					if (response.errors) {
						//console.log(response.errors[0].message)
						let errWaitTime = 0;
						if (response.extensions && response.extensions.cost) {
							const errCost = response.extensions.cost.requestedQueryCost;
							const errAvail =
								response.extensions.cost.throttleStatus.currentlyAvailable;
							const errRr = response.extensions.cost.throttleStatus.restoreRate;
							if (errCost > errAvail) {
								errWaitTime = ((errCost - errAvail) / errRr) * 1000;
							}
						}
						const newEmptyArray = [];
						return [newEmptyArray, transactionCursor, errWaitTime];
					} else {
						//console.log('response:',response)
						const res = response.data.tenderTransactions.edges;
						//console.log('res:',res)
						const newArray: any[] = [];

						for (let i = 0; i < res.length; i++) {
							if (!res[i]?.node?.order) {
								continue;
							}
							const amt = res[i].node.amount.amount;
							const date = res[i].node.processedAt;
							const taxExempt = res[i].node.order.customer?.taxExempt || res[i].node.order.taxExempt;
							const customer = res[i].node.order.customer;

							let transFees = 0;
							const taxLines = res[i]?.node?.order?.taxLines || [];
							let taxTotal = 0;
							taxLines.map((line) => {
								taxTotal += Number(line.priceSet.shopMoney.amount);
							});
							let gateway = "Other";

							gateway = res[i].node.order.transactions.find(
								(trans) =>
									trans.amountSet.shopMoney.amount === amt
							)?.formattedGateway
								|| res[i].node.order.transactions.find(
									(trans) =>
										trans.amountSet.shopMoney.amount * 1 === amt * -1
								)?.formattedGateway || "Other";




							if (gateway == "Other") {
								const refundTransactions = res[i].node.order.refunds.find(
									(refund) => {
										//console.log('checking for refund transactions: ',refund.transactions)
										refund.transactions.some((transaction) => {
											//console.log('does this transaction equal amt: ',transaction.amountSet.shopMoney.amount === amt,transaction.amoubtSet.shopMoney.amount,amt)
											transaction.amountSet.shopMoney.amount ===
												amt
										}
										)
									}


								);
								//console.log('refund transactions: ',refundTransactions)
								if (refundTransactions) {
									gateway =
										refundTransactions.transactions.find(
											(transaction) =>
												transaction.amountSet.shopMoney.amount ===
												amt
										)?.formattedGateway || "Other";
								}
							}





							let refundItems: any[] = [];


							//check for any refund items placed in the order metafields from the POS system
							const POSrefundItems: any[] = [];
							//console.log('testing these refund items',res[i].node.order.return_items)
							if (res[i].node.order.return_items?.value) {
								const refundItemsJSON = JSON.parse(res[i].node.order.return_items.value)
								refundItemsJSON.forEach((item) => {
									if (!processedLineItems.includes(item.node.id)) {
										processedLineItems.push(item.node.id)
										POSrefundItems.push(item)
										//console.log('adding id to processed items: ',item.node.id)
									}
								})
							}
							//console.log('refund items: ',POSrefundItems)


							//check for any refund transactions in the order metafields from the POS system
							const POSrefundTransactions: any[] = [];
							if (res[i].node.order.refund_transactions?.value) {
								const refundTransactionsJSON = JSON.parse(res[i].node.order.refund_transactions.value)
								//console.log('refund transactions from POS found: ',refundTransactionsJSON)
								refundTransactionsJSON.forEach((refundTransaction) => {
									if (!processedLineItems.includes(refundTransaction.id)) {
										processedLineItems.push(refundTransaction.id)
										POSrefundTransactions.push(refundTransaction)
										//console.log('refund transactions from POS found: ',refundTransaction)
									}
								})

							}




							//find the payout date by taking the transaction date, finding the next Monday deadline, and then adding two business days for Wednesday payout setting
							//needs to be modified if payout date or frequency changes
							//console.log(date)
							let tdate = new Date(Date.parse(date));
							//console.log(tdate)
							const timeZoneOffset = tdate.getTimezoneOffset();
							const utcTimestamp = tdate.getTime() + timeZoneOffset * 60 * 1000;
							let paydate: string | Date = new Date(utcTimestamp);
							tdate = new Date(utcTimestamp);
							//console.log(paydate)

							const daysUntilMonday = (1 + 7 - tdate.getDay()) % 7;
							const daysUntilWednesday = daysUntilMonday + 2;
							//console.log(daysUntilMonday)
							// add the number of days until the next Monday to the date object
							//paydate.setDate(paydate.getDate() + daysUntilWednesday);
							const options: Intl.DateTimeFormatOptions = {
								day: "2-digit",
								month: "2-digit",
								year: "numeric",
							};
							paydate = paydate.toLocaleDateString("en-ca", options);
							//console.log(`date ${date} is resulting in tdate of ${tdate} and paydate of ${paydate}`)
							//console.log(`date ${paydate} + ${(1 + 7 - paydate.getDay()) % 7} -> ${paydate}`)
							let lineItems: any = [];
							if (amt > 0) {
								lineItems = res[i].node?.order?.lineItems?.edges || [];
								lineItems = lineItems.filter((item) => {
									//console.log("checking: ", item.node.id, processedLineItems);
									if (processedLineItems.includes(item.node.id)) {
										//console.log("already in set");
										return false; // Do not include this item in the new array
									} else {
										//console.log("adding to set: ", item.node.id);
										processedLineItems.push(item.node.id);
										//console.log('adding item to list:',item.node.title)
										if (item.node.title == "N/A") {
											return false
										} else return true; // Include this item in the new array
									}
								});

								if (gateway?.includes("Stripe")) {
									//console.log('fees:',res[i].node.order.transactions)
									transFees = parseFloat((amt * 0.029 + 0.3).toFixed(2));
								} else if (gateway?.includes("Manual")) {
									transFees = parseFloat((amt * 0.029 + 0.3).toFixed(2));
								} else {
									for (
										let j = 0;
										j < res[i]?.node?.order?.transactions?.length;
										j++
									) {
										if (
											amt ===
											res[i].node.order.transactions[j].amountSet.shopMoney
												.amount &&
											res[i].node.order.transactions[j].fees.length > 0
										) {
											//console.log('fees:',res[i].node.order.transactions[j].fees)
											transFees =
												res[i].node.order.transactions[j].fees[0].amount.amount;
										}
									}
								}
							}
							if (amt < 0) {
								const refunds = res[i].node.order.refunds;
								//taxTotal = 0;
								//console.log('found a refund!',refunds)
								for (let jj = 0; jj < refunds.length; jj++) {
									for (
										let k = 0;
										k < refunds[jj].transactions.edges.length;
										k++
									) {
										const transaction =
											0 -
											refunds[jj].transactions.edges[k].node.amountSet.shopMoney
												.amount;
										//console.log(`Does ${transaction} = ${amt}?`)
										if (transaction == amt) {
											refundItems = refunds[jj].refundLineItems.edges;
											refundItems = refundItems.filter((item) => {
												//console.log("checking: ", item.node.id,processedLineItems);
												if (processedLineItems.includes(item.node.id)) {
													//console.log('already in set')
													return false; // Do not include this item in the new array
												} else {
													//console.log('adding to set: ',item.node.id)
													processedLineItems.push(item.node.id);
													return true; // Include this item in the new array
												}
											});
											//console.log('yes!')
										} else {
											//console.log('no')
										}
									}
								}
								//console.log('refundItems:',refundItems)
							}
							//console.log('refundItems:',refundItems)
							if (gateway === "Manual") {
								gateway = "Stripe";
							}
							if (gateway !== "Stripe" && gateway !== "Shopify Payments" && gateway !== "Tab" && gateway !== "Less Deposit") {
								newArray.push({
									amount: res[i].node.amount.amount,
									gateway: gateway,
									items: lineItems,
									refundItems: refundItems,
									fees: transFees,
									taxLines: taxLines,
									taxTotal: Number(taxTotal.toFixed(2)),
									date: date,
									paydate: paydate,
									source: 'POS',
									POSrefundItems: POSrefundItems,
									taxExempt: taxExempt,
									customer: customer
								});
								POSrefundTransactions.forEach((refund) => {
									newArray.push({
										amount: refund.amount,
										gateway: refund.gateway,
										fees: 0,
										date: date,
										taxTotal: 0,
										paydate: paydate,
										source: 'POS',
										taxExempt: taxExempt,
										customer: customer
									})
								})
							}
							//console.log('new line: ',newArray[i]);
						}
						//console.log('newArray:',newArray)
						if (response.data.tenderTransactions.pageInfo.hasNextPage) {
							const cost = response.extensions.cost.actualQueryCost;
							const estcost = response.extensions.cost.requestedQueryCost;
							const avail =
								response.extensions.cost.throttleStatus.currentlyAvailable;
							const rr = response.extensions.cost.throttleStatus.restoreRate;
							let waitTime = 1;
							if (cost > avail) {
								waitTime = ((cost - avail) / rr) * 1000;
							}
							const cursor = res[resultCount - 1].cursor;
							//console.log('cursor:',cursor)
							//let hasNextPage = response.data.tenderTransactions.pageInfo.hasNextPage
							return [newArray, cursor, waitTime];
						} else {
							return [newArray, "0", false];
						}
					}
				})
				.then((response) => {
					if (response[2]) {
						setAnotherPage(true);
						//console.log('partArray: ',partialTransactionArray.concat(response[0]))
						setPartialTransactionArray(
							partialTransactionArray.concat(response[0])
						);
						setTransactionCursor(response[1]);

						setTimeEst(response[2]);
						setTimeout(function () {
							//console.log('okay, done waiting.')
							setTriggerAnotherPOSPage(triggerAnotherPOSPage + 1);
						}, response[2]);
					} else {
						setAnotherPage(false);
						//console.log('last page has been loaded!')
						//console.log('partArray: ',partialTransactionArray.concat(response[0]))
						if (triggerAbort) {
							setWaiting(false);
							setTriggerAbort(false);
							//setStatusMessage('')
							setTransactionCursor("");
						} else {
							setTransactionArray(partialTransactionArray.concat(response[0]));
							//setStatusMessage('')
							setTransactionCursor("");
							setWaiting(false);
						}
					}
				});
		}
	}, [triggerAnotherPOSPage]); //Get all POS transactions from today

	useEffect(() => {
		//console.log(`searching for ${todayDate}`);
		setProducts([]);
		fetchProductsByDate(todayDate);
	}, [todayDate]);

	useEffect(() => {
		setPartialTransactionArray([]);
		//console.log("transactionArray:", transactionArray);
		//console.log('array changed:',transactionArray)
		listSalesByProcessor();
		listItems();
	}, [transactionArray]);

	useEffect(() => {
		//create category totals when itemList updates
		let catArray: string[] = [];
		for (let i = 0; i < itemList.length; i++) {
			catArray[i] = itemList[i].category;
		}
		catArray = Array.from(new Set(catArray)).sort();
		const amtArray = new Array(catArray.length).fill(0);
		const countArray = new Array(catArray.length).fill(0);
		for (let ii = 0; ii < itemList.length; ii++) {
			const item = itemList[ii];
			for (let j = 0; j < catArray.length; j++) {
				if (item.category === catArray[j]) {
					amtArray[j] += item.totalPrice;
					countArray[j] += item.quantity;
				}
			}
		}
	}, [itemList]);

	function getYesterday(date, timeZone = "America/Chicago") {
		const localDate = new Date(date.toLocaleString("en-US", { timeZone }));
		localDate.setDate(localDate.getDate() - 1);
		return localDate.toISOString().slice(0, 10);
	}
	function getToday(date, timeZone = "America/Chicago") {
		const localDate = new Date(date.toLocaleString("en-US", { timeZone }));
		localDate.setDate(localDate.getDate());
		return localDate.toISOString().slice(0, 10);
	}

	useEffect(() => {
		const fetchVisitsForToday = async (date) => {
			try {
				const formattedDate = moment(todaysDateValue).format('MM/DD/YY');

				//const formattedDate: Date = date.toLocaleDateString(); // Format the date as YYYY-MM-DD
				const response = await fetch(
					`/api/get-visits-by-date?date=${formattedDate}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${sessionStorage.getItem("token")}`,
						},
					}
				);
				const data = await response.json();

				const uniqueMembers = new Set();

				data.forEach(visit => {
					if (visit.membership_number) {
						uniqueMembers.add(visit.membership_number);
					}
				});

				// The size of the Set is the count of unique visitors
				const uniqueVisitCount = uniqueMembers.size;
				// Process the data as needed, e.g., count the number of visits
				const visitCount = data.length;
				setMemberCheckins(uniqueVisitCount)
				setBFFCheckins(visitCount - uniqueVisitCount)
				// Set state or perform other actions based on the visit count
			} catch (error) {
				console.error("Error fetching visits:", error);
				// Handle errors as appropriate
			}
		};
		const fetchGCsForToday = async (date) => {
			try {
				const formattedDate = moment(todaysDateValue).format('MM/DD/YY');

				//const formattedDate: Date = date.toLocaleDateString(); // Format the date as YYYY-MM-DD
				const response = await fetch(
					`/api/get-gift-cards-by-date?date=${formattedDate}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${sessionStorage.getItem("token")}`,
						},
					}
				);
				const data = await response.json();
				const gcCount = data.filter((cert) => cert.is_donation == false).length;
				const dCount = data.filter((cert) => cert.is_donation == true).length;
				setGcRedeems(gcCount)
				setDonationRedeems(dCount)
			} catch (error) {
				console.error("Error fetching GCs:", error);
				// Handle errors as appropriate
			}
		};

		getHours().then((hrs) => {
			const today = new Date(todaysDateValue);
			const yesterday = getYesterday(today);
			const todayRegister = getToday(today);
			let initStartDate = yesterday;
			let wc = 0;
			while (!thisHours(initStartDate, hrs) && wc <= 30) {
				initStartDate = minusOneDay(initStartDate);
				thisHours(initStartDate, hrs);
				wc += 1;
			}
			setStartDate(initStartDate);
			setStartDateRegister(todayRegister);
			setEndDate(yesterday);
			setEndDateRegister(todayRegister)
		});

		if (todaysDateValue) {
			//console.log('checking visits...')
			const today = new Date(todaysDateValue);
			fetchVisitsForToday(today);
			fetchGCsForToday(today);
		}
	}, [todaysDateValue]);


	useEffect(() => {
		fetch("/api/get-attendance", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`,
			},
			body: JSON.stringify({
				date: todaysDateValue
			}),
		})
			.then((response) => response.json())
			.then((data) => {

				type AttendanceObject = {
					id: number;
					category: string;
					timestamp: string;
					quantity: number;
					order_number: string;
					date: string;
					cancelled: boolean;
				}


				const attendenceObjects: AttendanceObject[] = data;

				const attCounts = {
					"BFF": 0,
					"Gift Card": 0,
					"GoKarts": 0,
					"Group": 0,
					"Indoor": 0,
					"Last 3 Hours": 0,
					"Member": 0,
					"Online": 0,
					"Party": 0,
					"Switchback": 0,
					"Unlimited": 0,
					"Walk-In": 0,
				};

				attendenceObjects.forEach((attend) => {
					if (!attend.cancelled) {
						const thisCategory = attend.category;
						attCounts[thisCategory] = (attCounts[thisCategory] || 0) + Number(attend.quantity);
					}
				})



				setVisitorCount(attCounts);
				// Handle success - e.g., update UI or state
				fetch("/api/get-calendar-attendance", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					},
					body: JSON.stringify({
						date: todaysDateValue
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						console.log('calendar attendance data:', data);


						const attendenceObjects: AttendanceObject[] = data;

						const attCounts = {
							"Street": 0,
							"Online": 0,
							"Group": 0,
							"Member": 0,
							"Gift Card": 0,
						};

						attendenceObjects.forEach((attend) => {
							if (!attend.cancelled) {
								const thisCategory = attend.category;
								attCounts[thisCategory] = (attCounts[thisCategory] || 0) + Number(attend.quantity);
							}
						})



						setCalendarCount(attCounts);
						// Handle success - e.g., update UI or state
					})
					.catch((error) => {
						// Handle error - e.g., show error message to user
					});
			})
			.catch((error) => {
				// Handle error - e.g., show error message to user
			});
	}, [todaysDateValue,refreshAttendance]);

	const fetchProductsByDate = (date) => {
		fetch(`/api/search-variants/${date}`, {
			headers: {
				"Content-type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`,
			},
		})
			.then((res) => res.json())
			.then((data) => {
				setProducts(data.data.productVariants.edges.map((edge) => edge.node));
			})
			.catch((error) => console.error(error.message));
	};

	const listSalesByProcessor = () => {

		let procArray: any[] = [];
		let paydateArray: any[] = [];
		let ttl = 0;

		for (let ii = 0; ii < transactionArray.length; ii++) {
			procArray[ii] = transactionArray[ii]?.gateway;
			paydateArray[ii] = transactionArray[ii]?.paydate;
		}

		// Create unique sets of processors and paydates, and sort them
		procArray = Array.from(new Set(procArray)).sort();
		paydateArray = Array.from(new Set(paydateArray)).sort();

		// Preferred processors
		const preferredProcessors = ["Shopify Payments", "Stripe", "Cash", "Quickbooks Payments","ACH"];
		const ignoredProcessors = ["Na"];



		// Merge preferred processors with discovered processors, ensuring all preferred are included
		if (transactionArray.length > 0) procArray = preferredProcessors.concat(procArray.filter(proc => !preferredProcessors.includes(proc))).filter(proc => !ignoredProcessors.includes(proc));

		// 2D array initialization for processor/paydate combinations
		const procAndPaydateArray = [procArray, paydateArray];

		// Initialize 2D arrays for storing amounts and fees
		const amtArray = Array(procArray.length)
			.fill([])
			.map(() => Array(paydateArray.length).fill(0));
		const feeArray = Array(procArray.length)
			.fill([])
			.map(() => Array(paydateArray.length).fill(0));

		// Calculate totals for each processor and paydate
		transactionArray.forEach(transaction => {
			const paydateIndex = paydateArray.indexOf(transaction.paydate);
			const processorIndex = procArray.indexOf(transaction.gateway);

			if (paydateIndex !== -1 && processorIndex !== -1) {
				const amount = Number(transaction.amount);
				const fees = Number(transaction.fees);

				// Update amount and fees for this transaction
				amtArray[processorIndex][paydateIndex] += amount;
				feeArray[processorIndex][paydateIndex] += fees;

				// Update total transactions amount
				ttl += amount;
			}
		});

		// Store the totals and 2D arrays in your state or wherever needed
		setProcTotals([procAndPaydateArray, amtArray, feeArray]);
		setProcTotal(ttl);

	};


	const renderProducts = () => {
		//const filteredProducts = products.filter((product) => product.inventoryQuantity < 0);
		const filteredProducts = products;

		/*
		const totalAttendanceCount = filteredProducts.reduce((acc, product) => {
			const attendanceCount = product.sku.includes("SDFD")
			? Math.abs(product.inventoryQuantity) * 4
			: Math.abs(product.inventoryQuantity);
			return acc + attendanceCount;
		}, 0);*/
		//console.log('checking: ',filteredProducts)
		const totalAttendanceCount = filteredProducts.reduce((acc, product) => {
			//console.log('checking: ',product.product.title)
			const attendanceCount = product.sku.includes("FFP")
				? Math.abs(product.inventoryQuantity) * 4
				: product.product.title.includes("Party")
					? 0
					: Math.abs(product.inventoryQuantity);
			return acc + attendanceCount;
		}, 0);

		const resetInventory = async (variantId, inventoryQuantity) => {
			const delta = -inventoryQuantity;
			try {
				await fetch(`/api/update-inventory`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					},
					body: JSON.stringify({
						variantId: variantId,
						delta: delta,
					}),
				});

				setUpdatedInventory((prevInventory) => ({
					...prevInventory,
					[variantId]: 0,
				}));
			} catch (error) {
				console.error("Error resetting inventory:", error);
			}
		};

		const deleteVariant = async (variantId) => {
			try {
				await fetch(`/api/delete-variant`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					},
					body: JSON.stringify({
						variantId: variantId,
					}),
				});

				setUpdatedInventory((prevInventory) => ({
					...prevInventory,
					[variantId]: 0,
				}));
			} catch (error) {
				console.error("Error deleting variant:", error);
			}
		};
		const deleteProduct = async (productId) => {
			try {
				await fetch(`/api/delete-product`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					},
					body: JSON.stringify({
						productId: productId,
					}),
				});
			} catch (error) {
				console.error("Error deleting variant:", error);
			}
		};

		/*const resetAll = async () => {
			for (const product of filteredProducts) {
			  if (hasNumbers(product.sku)) {
				await deleteVariant(product.id);
			  } else {
				await resetInventory(product.inventoryItem.inventoryLevels.edges[0].node.id, product.inventoryQuantity);
			  }
			}
		};*/

		const resetAll = async () => {
			const promises: Promise<any>[] = [];

			for (const product of filteredProducts) {
				if (product.product.title.includes("Party")) {
					promises.push(deleteProduct(product.product.id));
				} else if (hasNumbers(product.sku)) {
					promises.push(deleteVariant(product.id));
				} else {
					promises.push(
						resetInventory(
							product.inventoryItem.inventoryLevels.edges[0].node.id,
							product.inventoryQuantity
						)
					);
				}
			}

			await Promise.all(promises);
			toast.success("All Products have been reset or deleted!", {
				position: toast.POSITION.BOTTOM_CENTER,
			});
		};

		const hasNumbers = (str) => /\d/.test(str);

		const renderResetOrDeleteButton = (product) => {
			if (hasNumbers(product.sku)) {
				return (
					<button
						className="deleteButton"
						onClick={() => deleteVariant(product.id)}
					>
						Delete
					</button>
				);
			} else {
				return (
					<button
						className="resetButton"
						onClick={() =>
							resetInventory(
								product.inventoryItem.inventoryLevels.edges[0].node.id,
								product.inventoryQuantity
							)
						}
					>
						Reset
					</button>
				);
			}
		};

		if (procTotals[1][0]) {
			return (
				<div>
					<br />
					<br />
					<table className="report-table">
						<thead>
							<tr>
								<th>Title </th>
								<th> SKU </th>
								<th> Inventory </th>
								<th> Attendance Count </th>
								<th className="resetButton"> Reset / Delete </th>
							</tr>
						</thead>
						<tbody>
							{filteredProducts.map((product) => {
								const attendanceCount = product.sku.includes("FFP")
									? Math.abs(product.inventoryQuantity) * 4
									: product.product.title.includes("Party")
										? 0
										: Math.abs(product.inventoryQuantity);
								const updatedInventoryQuantity =
									updatedInventory[
									product.inventoryItem.inventoryLevels.edges[0].node.id
									];
								return (
									<tr key={product.id}>
										<td>{product.product.title} </td>
										<td> {product.sku} </td>
										<td>
											{" "}
											{updatedInventoryQuantity !== undefined
												? updatedInventoryQuantity
												: Math.abs(product.inventoryQuantity)}
										</td>
										<td> {attendanceCount} </td>
										<td className="resetButton">
											{renderResetOrDeleteButton(product)}
										</td>
									</tr>
								);
							})}
						</tbody>
						<tfoot>
							<tr>
								<td>Total </td>
								<td> </td>
								<td> </td>
								<td id="todayAttendanceCount"> {totalAttendanceCount} </td>
								<td className="resetButton">
									<button onClick={resetAll}>Reset / Delete All</button>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			);
		} else {
			return;
		}
	};

	const createProcReport = () => {
		if (procTotal > 0) {
			//let procTtl = 0;
			const dateRows = procTotals[0][1].map((date, y) => {
				const processorCols = procTotals[0][0].map((processor, i) => (
					<>
						<td>{procTotals[1][i][y].toFixed(2)} </td>
						<td> {procTotals[2][i][y].toFixed(2)} </td>
					</>
				));

				if (y === procTotals[0][1].length - 1) {
					return <tr key={date}>{processorCols}</tr>;
				}
			});

			if (procTotals[0][1].length > 1) {
				const previousDatesRow = procTotals[0][0].map((processor, i) => {
					const [totalSales, totalFees] = procTotals[1][i].slice(0, -1).reduce(
						(acc, value, j) => {
							acc[0] += procTotals[1][i][j];
							acc[1] += procTotals[2][i][j];
							return acc;
						},
						[0, 0]
					);
					//procTtl += totalSales;
					return (
						<>
							<td>{totalSales.toFixed(2)}</td>
							<td> {totalFees.toFixed(2)} </td>
						</>
					);
				});
				dateRows.splice(
					-2,
					1,
					<tr key="previousDatesRow">{previousDatesRow}</tr>
				);
			} //else {
			//console.log(`reducing ${procTotals[1]}...`)
			//procTtl = procTotals[1].reduce((a,b) => a + b.reduce((a,b) => a + b), 0);
			//}
			//console.log('procTtl: ',procTtl)
			//const referencepoint = React.createRef();
			return (
				<div>
					<h2>Total Online Sales: $ {procTotal.toFixed(2)} </h2>
					<h4> Online Attendance Count: {attendanceCount} </h4>
					<br />
					<table className="salesByProcessor report-table" id="procsales">
						<thead>
							<tr>
								<th colSpan={2 * procTotals[0][0].length + 1}>
									{" "}
									Sales By Processor{" "}
								</th>
							</tr>
							<tr>
								{procTotals[0][0].map((processor) => (
									<>
										<th>{processor} </th>
										<th> Fees </th>
									</>
								))}
							</tr>
						</thead>
						<tbody>{dateRows}</tbody>
					</table>
				</div>
			);
		} else {
			if (waiting) {
				return (
					<div>
						<br />
						<br /> <br />
						<span className="statusMessage"> {statusMessage} </span>
						<br />
						<button className="smallButton" onClick={abort}>
							{" "}
							Abort Search{" "}
						</button>
					</div>
				);
			} else {
				return <div></div>;
			}
		}
	};
	const listItems = () => {
		const itemArray = [];
		let attCount = 0;
		let regCount = 0;

		for (let i = 0; i < transactionArray.length; i++) {
			const tran = transactionArray[i];
			// Handle POS refund items
			for (let j = 0; j < (tran?.POSrefundItems?.length || 0); j++) {
				const item = tran.POSrefundItems[j]?.node;
				if (item?.lineItem?.title) {
					const itemPrice = item.quantity * item.priceSet.shopMoney.amount * -1;
					itemArray.push({
						title: item.lineItem.title,
						quantity: item.quantity * -1,
						taxed: tran.taxLines ? 1 : 0,
						price: item.priceSet.shopMoney.amount * -1,
						totalPrice: itemPrice,
						category: "",
						source: transactionArray[i].source,
						vendor: item.lineItem.vendor || '',
						taxExemptTransaction: tran.taxExempt ? true : false
					});
				}
			}

			// Handle sales and refund items
			const isRefund = (transactionArray[i].amount || 0) < 0;
			const transactionItems = isRefund ? tran.refundItems : tran.items;

			if (transactionItems && transactionItems.length > 0) {
				itemArray.push({
					title: "Sales Tax",
					quantity: isRefund ? -1 : 1,
					taxed: 1,
					price: transactionArray[i].taxTotal,
					totalPrice: transactionArray[i].taxTotal,
					category: "",
					source: transactionArray[i].source,
					taxExemptTransaction: tran.taxExempt ? true : false
				});

				for (let j = 0; j < transactionItems.length; j++) {
					const item = transactionItems[j]?.node;
					if (item) {
						//console.log("item", item);
						const itemPrice = isRefund ? item.quantity * item.priceSet.shopMoney.amount * -1 : item.quantity * item.discountedUnitPriceSet.shopMoney.amount;
						itemArray.push({
							title: isRefund ? item.lineItem.title : item.title,
							quantity: isRefund ? item.quantity * -1 : item.quantity,
							taxed: (transactionArray[i].taxLines && transactionArray[i].taxLines.length > 0) && item.taxable ? 1 : 0,
							price: isRefund ? item.priceSet.shopMoney.amount * -1 : item.discountedUnitPriceSet.shopMoney.amount,
							totalPrice: itemPrice,
							category: "",
							source: transactionArray[i].source,
							vendor: isRefund ? item.lineItem.vendor : item.vendor || '',
							taxExemptTransaction: tran.taxExempt ? true : false
						});
					}
				}
			}
		}

		for (let i = 0; i < itemArray.length; i++) {
			const item = itemArray[i];

			if (item.vendor?.match(/merchandise/i)) {
				item.category = "Merchandise";
			} else if (item.vendor?.match(/food|drinks/i)) {
				item.category = "Food";
			}else if (item.vendor?.match(/consignment/i)) {
				item.category = "Consignment";
			} else if (item.vendor?.match(/groups/i)) {
				item.category = "Groups";
				if (item.source === "POS" && !item.title?.includes("Serving")) regCount += item.quantity;
				else if (item.source !== "POS") attCount += item.quantity;
			} else if (item.vendor?.match(/parties/i)) {
				item.category = "Parties";
				if (item.source === "POS" && !item.title?.includes("Serving")) regCount += item.quantity;
				else if (item.source !== "POS") attCount += item.quantity;
			} else if (item.vendor?.includes("Admission")) {
				item.category = "Admission";
				if (item.source === "POS") regCount += item.quantity;
				else if (item.source !== "POS") attCount += item.quantity;
			} else if (item.vendor?.includes("4-Packs")) {
				item.category = "Admission";
				if (item.source === "POS") regCount += item.quantity * 4;
				else if (item.source !== "POS") attCount += item.quantity * 4;
			} else if (item.vendor?.includes("Consignment")) {
				item.category = "Consignment";
				if (item.source === "POS") regCount += item.quantity;
				else if (item.source !== "POS") attCount += item.quantity;
			} else if (item.title?.includes("Party") && !item.title?.includes("Deposit")) {
				item.category = "Parties";
			} else if (item.title?.includes("GetOutPass")) {
				item.category = "Consignment";
				if (item.source !== "POS") attCount += item.quantity || 1;
				else regCount += item.quantity || 1;
			} else if (item.title?.includes("Gift")) {
				item.category = "Gift Cards";
				if (item.source !== "POS") attCount += item.quantity || 1;
			} else if (item.title?.includes("Meal") || item.title?.includes("Upgrade to Eat & Play Combo")) {
				item.category = "Meal Bands";
			} else if (item.title?.includes("Member")) {
				item.category = "Memberships";
			} else if (item.title?.includes("Pass")) {
				item.category = "Passes";
			} else if (item.title?.includes("Fun Pack") || item.title?.includes("Friendzy")) {
				item.category = "4-packs";
				if (item.source !== "POS") attCount += (item.quantity || 1) * 4;
				else regCount += (item.quantity || 1) * 4;
			} else if (item.title?.includes("Chaperone")) {
				item.category = "Groups";
			} else if (item.title?.includes("ITT Base")) {
				item.category = "Consignment";
				if (item.source !== "POS") attCount += (item.quantity || 1);
				else regCount += (item.quantity || 1);
			} else if (item.title?.includes("band")) {
				item.category = "Admission";
				if (item.source !== "POS") attCount += (item.quantity || 1);
				else regCount += (item.quantity || 1);
			} else if (item.title?.includes("Sales Tax")) {
				item.category = "Sales Tax";
			} else {
				item.category = "Other";
			}
		}

		// Filter tax-exempt transactions and calculate total tax-free amount
		let taxExemptTransactions = transactionArray.filter(transaction => transaction.taxExempt);
		taxExemptTransactions = taxExemptTransactions.filter(transaction => Number(transaction.amount) !== 0.01 && Number(transaction.amount) !== -0.01);
		//console.log('taxExemptTransactions: ', taxExemptTransactions);
		const taxFree = taxExemptTransactions.reduce((total, transaction) => {
			const amount = Number(transaction.amount) || 0;
			return total + amount;
		}, 0);
		setTotalTaxFree(taxFree);
		///console.log('taxFree: ', taxFree);

		// Find all tax-exempt items using the itemArray
		const taxFreeItems = itemArray.filter(item => item.taxed === 0 && item.category !== "Consignment"
			&& !item.title.includes("Deposit") && !item.taxExemptTransaction && item.totalPrice > 0);
		//console.log('taxFreeItems: ', taxFreeItems);
		const totalTaxFreeItemsAmount = taxFreeItems.reduce((acc, item) => acc + item.totalPrice, 0);
		setTotalTaxFree(prev => prev + totalTaxFreeItemsAmount);
		//console.log('totalTaxFreeItems: ', totalTaxFreeItemsAmount);

		setAttendanceCount(attCount);
		setPOSattendanceCount(regCount);
		setItemList(itemArray);

		if (attCount && attCount !== calendarCount["Online"]) {
			console.log('need to correct the calendar count!');
			console.log(attCount, calendarCount["Online"]);
			const newCount = attCount - calendarCount["Online"];
			console.log('need to add: ', newCount);
		
			// Use window.confirm() to ask the user
			if (window.confirm(`The online attendance count (${attCount}) doesn't match the calendar count (${calendarCount["Online"]}). Do you want to submit a correction of ${newCount}?`)) {
				const calendarObject = {
					category: "Online",
					quantity: newCount,
					date: todaysDateValue,
				}
				fetch("/api/add-calendar-attendance", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					},
					body: JSON.stringify(calendarObject)
				})
					.then((response) => response.json())
					.then((data) => {
						setRefreshAttendance(prev => prev + 1)
						console.log('Correction submitted!')
						// Handle success - e.g., update UI or state
					})
					.catch((error) => {
						console.error('Error submitting correction:', error);
						// Handle error - e.g., show error message to user
					});
			} else {
				// User chose not to submit the correction
				console.log('User cancelled the correction submission.');
			}
			} else {
				console.log('No correction needed.', attCount, calendarCount["Online"]);
			}
		

	};




	const taxFreeReport = () => {
		if (transactionArray.length == 0) {
			return;
		}
		let taxExemptTransactions = transactionArray.filter(transaction => transaction.taxExempt);

		//filter transactions with amounts of 0.01 or -0.01
		taxExemptTransactions = taxExemptTransactions.filter(transaction => Number(transaction.amount) !== 0.01 && Number(transaction.amount) !== -0.01);

		if (taxExemptTransactions.length === 0) {
			return;
		}

		return (
			<div className="tax-free-report">
				<h3>Tax-Exempt Transactions</h3>
				<table className="report-table">
					<thead>
						<tr>
							<th>Transaction Amount</th>
							<th>Customer Name</th>
							<th>Customer Email</th>
						</tr>
					</thead>
					<tbody>
						{taxExemptTransactions.map((transaction, index) => (
							<tr key={index}>
								<td>{transaction.amount}</td>
								<td>{transaction.customer?.displayName || 'N/A'}</td>
								<td>{transaction.customer?.email || 'N/A'}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};

	const taxFreeItemReport = () => {
		if (itemList.length === 0) {
			return;
		}



		// Filter out consignment items
		let taxFreeItems = itemList.filter(item =>
			item.taxed === 0
			&& item.category !== "Consignment"
			&& !item.title.includes("Deposit")
			&& !item.taxExemptTransaction
			&& item.totalPrice > 0);
		//console.log('taxFreeItems: ', taxFreeItems);
		// Combine tax-free items with the same title, adding their amounts
		taxFreeItems = taxFreeItems.reduce((acc, item) => {
			const existingItem = acc.find(i => i.title === item.title);
			if (existingItem) {
				existingItem.totalPrice += item.totalPrice;
			} else {
				acc.push({ ...item });
			}
			return acc;
		}, []);

		if (taxFreeItems.length === 0) {
			return;
		}

		return (
			<div className="tax-free-report">
				<h3>Tax-Exempt Items</h3>
				<table className="report-table">
					<thead>
						<tr>
							<th>Amount</th>
							<th>Item</th>
						</tr>
					</thead>
					<tbody>
						{taxFreeItems.map((item, index) => (
							<tr key={index}>
								<td>{item.totalPrice.toFixed(2)}</td>
								<td>{item.title}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};





	const createItemReport = () => {
		if (procTotals[1][0]) {
			const items = itemList;
			const itemArray = [["Item Name"], ["#"], ["$"], ["Category"]];
			let amtTtl = 0;
			//console.log('items: ', items);
			for (let i = 0; i < items.length; i++) {
				const item = items[i];
				if (item.title && item.title.includes("Party Deposit")) {
					item.title = "Party Deposit";
				}
				let matched = false;
				for (let j = 0; j < itemArray[0].length; j++) {
					const sameTitle = item.title === itemArray[0][j];
					const sameCategory = item.category === itemArray[3][j];
					if (!matched && sameTitle && sameCategory) {
						itemArray[1][j] += item.quantity;
						itemArray[2][j] = (
							parseFloat(itemArray[2][j]) + item.totalPrice
						).toFixed(2);
						matched = true;
					}
				}

				if (!matched) {
					itemArray[0].push(item.title);
					itemArray[1].push(item.quantity);
					itemArray[2].push(item.totalPrice.toFixed(2));
					itemArray[3].push(item.category);
				}

				amtTtl += item.totalPrice;
			}

			// Sort the itemArray alphabetically by item name, ensuring that all items have defined titles
			const sortedItemArray = [[], [], [], []];
			const sortedIndexes = itemArray[0]
				.map((item, index) => ({ item, index }))
				.filter(({ item }) => item !== undefined && item !== null) // Filter out undefined or null titles
				.sort((a, b) => a.item.localeCompare(b.item))
				.map(({ index }) => index);

			sortedIndexes.forEach((sortedIndex) => {
				sortedItemArray[0].push(itemArray[0][sortedIndex]);
				sortedItemArray[1].push(itemArray[1][sortedIndex]);
				sortedItemArray[2].push(itemArray[2][sortedIndex]);
				sortedItemArray[3].push(itemArray[3][sortedIndex]);
			});

			if (amtTtl.toFixed(2) !== procTotal.toFixed(2)) {
				sortedItemArray[0].push("Other");
				sortedItemArray[1].push("-");
				sortedItemArray[2].push((procTotal - amtTtl).toFixed(2));
				sortedItemArray[3].push("-");
				amtTtl += procTotal - amtTtl;
			}

			sortedItemArray[0].push("Total");
			sortedItemArray[1].push("-");
			sortedItemArray[2].push(amtTtl.toFixed(2));
			sortedItemArray[3].push("-");

			return (
				<div>
					<div className="salesByItem">
						<h3>Sales by Item: </h3>
						<table className="salesByItem report-table">
							<tbody>
								{sortedItemArray.map((numList, i) => (
									<tr key={i}>
										{numList.map((num, j) => (
											<td key={j}> {num} </td>
										))}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			);
		} else {
			return <div></div>;
		}
	};





	const dateSearch = () => {
		setTriggerDateChange(triggerDateChange + 1);
	};

	const dateSetter = () => {
		if (waiting) {
			return (
				<div>
					<h4>
						{startDate} to {endDate}{" "}
					</h4>
				</div>
			);
		} else {
			return (
				<div>
					<h3>Today's Date:</h3>
					<input
						type="date"
						id="todays"
						name=""
						value={todaysDateValue}
						onChange={(e) => {
							const parsedDate = new Date(e.target.value + "T00:00:00");
							const timezoneOffset = parsedDate.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
							const localDate = new Date(parsedDate.getTime() + timezoneOffset);

							setTodayDate(
								new Intl.DateTimeFormat("en-US", {
									timeZone: "America/Chicago",
									month: "2-digit",
									day: "2-digit",
									year: "2-digit",
								})
									.format(localDate)
									.split("/")
									.join("")
									.slice(0, 6)
							);
							setTodaysDateValue(e.target.value);
							//console.log('new date set!')
						}}
					/>
					<br /><br />
					<h3>Visitor Count: </h3>
					{Object.keys(visitorCount).map((key) => (
						visitorCount[key] ?
							<span key={key}>{key}: {visitorCount[key]}&emsp;</span>
							: null
					))}
					<h3>Calendar Count: </h3>
					{Object.keys(calendarCount).map((key) => (
						calendarCount[key] ?
							<span key={key}>{key}: {calendarCount[key]}&emsp;</span>
							: null
					))}

					<h3> Date range for Processor Reports: </h3>
					<input
						type="date"
						id="start"
						name="date-start"
						max={endDate}
						value={startDate}
						onChange={function (e) {
							setStartDate(e.target.value);
						}}
					/>
					&emsp; to &emsp;
					<input
						type="date"
						id="end"
						name="date-end"
						min={startDate}
						value={endDate}
						onChange={function (e) {
							setEndDate(e.target.value);
						}}
					/>
					<h3> Date range for Register Reports: </h3>
					<input
						type="date"
						id="start"
						name="date-start"
						max={endDateRegister}
						value={startDateRegister}
						onChange={function (e) {
							setStartDateRegister(e.target.value);
						}}
					/>
					&emsp; to &emsp;
					<input
						type="date"
						id="end"
						name="date-end"
						min={startDateRegister}
						value={endDateRegister}
						onChange={function (e) {
							setEndDateRegister(e.target.value);
						}}
					/>
					{procTotals[0][0]?.length > 0 ? (
						``
					) : (
						<button className="smallButton" onClick={dateSearch}>
							{" "}
							Go{" "}
						</button>
					)}
				</div>
			);
		}
	};

	const abort = () => {
		setTriggerAbort(true);
	};

	function delay(time) {
		return new Promise((resolve) => setTimeout(resolve, time));
	}

	if (window.location.href.includes("?go=true")) {
		const newUrl = window.location.href.split("?go=")[0];
		//console.log('new url: ',newUrl)
		window.history.pushState({}, 'Daily Reports', newUrl);
		delay(500).then(() => dateSearch());
	}

	const createCategoryReport = () => {
		if (procTotals[1][0]) {
			const categoryArray: any = [[], [], []];
			const possibleCategories = [
				"Passes",
				"Memberships",
				"Gift Cards",
				"Tax-Free Groups",
				"Merchandise",
				"Food",
				"Groups",
				"Parties",
				"Tax-Free",
				"Consignment"
			];
			const categoryTotals = {};
			for (let i = 0; i < itemList.length; i++) {
				const item = itemList[i];
				const category = item.category;

				if (!categoryTotals[category]) {
					categoryTotals[category] = { quantity: 0, totalPrice: 0 };
				}
				categoryTotals[category].totalPrice += Number(item.totalPrice);
				categoryTotals[category].quantity += Number(item.quantity);
			}
			for (let i = 0; i < possibleCategories.length; i++) {
				const thisPossibleCategory = possibleCategories[i];
				let totalPrice = 0;
				let totalQuantity = 0;
				if (categoryTotals[thisPossibleCategory]) {
					totalPrice = categoryTotals[thisPossibleCategory].totalPrice;
					totalQuantity = categoryTotals[thisPossibleCategory].quantity;
				}
				categoryArray[0].push(thisPossibleCategory);
				if (thisPossibleCategory === 'Tax-Free') {
					categoryArray[1].push(totalTaxFree.toFixed(2));
				} else {
					categoryArray[1].push(totalPrice.toFixed(2));
				}
				categoryArray[2].push(totalQuantity);
			}
			return (
				<div>
					<h3>Sales by Category: </h3>
					<table className="salesByCategory report-table" id="catTable">
						<tbody>
							{categoryArray.map((numList, i) => (
								<tr key={i}>
									{numList.map((num, j) => (
										<td key={j}> {num} </td>
									))}
								</tr>
							))}
						</tbody>
					</table>
					<button
						className="smallButton"
						onClick={() => {
							setIsCopying(true);
							const trs = [...document.querySelectorAll("#procsales tbody tr")];
							let tableData = trs
								.map((tr) => {
									const tds = [...tr.querySelectorAll("td")];
									return tds.map((td) => td.innerText).join("\t");
								})
								.join("\n");

							const catTableRow = [
								...document.querySelectorAll("#catTable tbody tr"),
							][1];
							const catTableData = [...catTableRow.querySelectorAll("td")]
								.map((td) => td.innerText)
								.join("\t");

							tableData =
								tableData + "\n" + catTableData
								+ "\n" + visitorCount["Online"]
								+ "\t" + visitorCount["Member"]
								+ "\t" + visitorCount["BFF"]
								+ "\t" + visitorCount["Gift Card"]
								+ "\t" + visitorCount["Unlimited"]
								+ "\t" + visitorCount["Indoor"]
								+ "\t" + visitorCount["Group"]
								+ "\t" + visitorCount["GoKarts"]
								+ "\t" + visitorCount["Last 3 Hours"]
								+ "\t" + visitorCount["Party"]
								+ "\t" + visitorCount["Switchback"]
								+ "\t" + visitorCount["Walk-In"]
								+ "\n" + calendarCount["Street"]
								+ "\t" + calendarCount["Online"]
								+ "\t" + calendarCount["Member"]
								+ "\t" + calendarCount["Group"]
								+ "\t" + calendarCount["Gift Card"]

							if (trs.length === 1) {
								tableData = "0\t0\t0\t0\t\n" + tableData;
							}
							navigator.clipboard.writeText(tableData);
							setTimeout(() => setIsCopying(false), 1000);
						}}
					>
						{" "}
						{isCopying ? "Copying..." : "Copy to Clipboard"}{" "}
					</button>
				</div>
			);
		} else {
			return <div></div>;
		}
	};



	const itemizedReport = () => {
		if (transactionArray.length == 0) {
			return;
		}
		return (
			<div className="itemizedReport newPage">
				<br />
				<br /> <hr />
				<br /> <br />
				<table className="report-table">
					<thead>
						<tr>
							<th>Total Amount </th>
							<th> Fee Amount </th>
							<th> Processor </th>
							<th> Estimated Payout Date </th>
							<th> Transaction Date </th>
						</tr>
					</thead>
					<tbody>
						{transactionArray.map((transaction, index) => (
							<tr key={index}>
								<td>{transaction.amount} </td>
								<td> {transaction.fees} </td>
								<td> {transaction.gateway} </td>
								<td> {transaction.paydate} </td>
								<td>
									{" "}
									{new Date(transaction.date || 0).toLocaleDateString(undefined, {
										year: "numeric",
										month: "long",
										day: "numeric",
									}) +
										" " +
										new Date(transaction.date || 0).toLocaleTimeString()}{" "}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};

	return (
		<div className="reportswindow">
			<div className="report">
				<h1>Sales Report </h1>
				{dateSetter()}
				{createProcReport()}
				{createCategoryReport()}
				{renderProducts()}
				{taxFreeReport()}
				{taxFreeItemReport()}
				{createItemReport()}
				{itemizedReport()}

			</div>
		</div>
	);
}
