import React, { useState, useEffect } from 'react';
import './MembersTable.scss';

const MembersTable = () => {
    const [members, setMembers] = useState([]);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [editingMembershipNumber, setEditingMembershipNumber] = useState(null);
    const [editedMember, setEditedMember] = useState({});
    const [nameFilter, setNameFilter] = useState('');
    const [changedFields, setChangedFields] = useState([]);
    const [onlyWithSubId, setOnlyWithSubId] = useState(true);
    const [sortField, setSortField] = useState('membership_number');
    const [sortDirection, setSortDirection] = useState('desc');
    const [isRefreshingAll, setIsRefreshingAll] = useState(false);
    const [progressMessage, setProgressMessage] = useState('');





    useEffect(() => {
        fetchMembers();
    }, []);

    useEffect(() => {
        applyFilters();
      }, [members, nameFilter, onlyWithSubId, sortField,sortDirection]);
      

    // Fetch all members from the backend
    const fetchMembers = async () => {
        try {
            const response = await fetch('/api/members', {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                },
            });

            if (!response.ok) {
                console.error('Failed to fetch members');
                return;
            }

            const data = await response.json();
            setMembers(data);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    const handleRefreshMember = async (member) => {
        if (!member.sub_id) {
          alert('This member does not have a subscription ID.');
          return;
        }
      
        try {
          // Fetch Subscription Details
          const response = await fetch(`/api/get-subscription-details/${member.sub_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
              'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            },
          });
      
          if (!response.ok) {
            const errorMessage = await response.text();
            console.error('Failed to fetch subscription details:', errorMessage);
            alert(`Error: ${errorMessage}`);
            return;
          }
      
          const data = await response.json();
          const subscriptionData = data.payload;
      
          // Process the subscription data to get updated member data
          const updatedMemberData = processSubscriptionData(subscriptionData, member);
      
          // Fetch Visits Data
          const visitsResponse = await fetch(
            `/api/get-visits?membership_numbers=${member.membership_number}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
              },
            }
          );
      
          if (!visitsResponse.ok) {
            const errorMessage = await visitsResponse.text();
            console.error('Failed to fetch visits:', errorMessage);
            alert(`Error fetching visits: ${errorMessage}`);
            return;
          }
      
          const visitsData = await visitsResponse.json();
      
          // Process Visits Data
          const totalVisits = visitsData.length;
          const lastVisitDate = visitsData.length > 0 ? visitsData[0].visit_timestamp : null;
      
          // Update the member data with visits info
          updatedMemberData.visits = totalVisits;
          updatedMemberData.last_visit = lastVisitDate;
      
          // Compare updatedMemberData with member to find changed fields
          const fieldsChanged = [];
          for (const key in updatedMemberData) {
            if (updatedMemberData[key] !== member[key]) {
              fieldsChanged.push(key);
            }
          }
      
          // Set editing mode with updated data
          setEditingMembershipNumber(member.membership_number);
          setEditedMember({
            ...member,
            ...updatedMemberData,
            valid_until: formatForInputDate(updatedMemberData.valid_until || member.valid_until),
            signup_date: formatForInputDate(updatedMemberData.signup_date || member.signup_date),
            due_date: formatForInputDate(updatedMemberData.due_date || member.due_date),
            dob: formatForInputDate(updatedMemberData.dob || member.dob),
            last_visit: formatForInputDate(updatedMemberData.last_visit || member.last_visit),
          });
          setChangedFields(fieldsChanged);
        } catch (error) {
          console.error('Error fetching subscription details or visits:', error);
          alert('An error occurred while fetching data.');
        }
      };

      const handleRefreshAndSaveAll = async () => {
        setIsRefreshingAll(true);
        setProgressMessage('Starting refresh and save of all members...');
        const totalMembers = filteredMembers.length;
        let processedCount = 0;

        for (const member of filteredMembers) {
        processedCount++;

          if (!member.sub_id) {
            // Skip members without a sub_id
            setProgressMessage(
                `Processed ${processedCount} of ${totalMembers} members. Skipped member ${member.membership_number} (${member.name}) - No Sub ID.`
              );
            continue;
          }
          try {
            // Fetch Subscription Details
            setProgressMessage(
                `Processing member ${member.membership_number} (${member.name})... (${processedCount} of ${totalMembers})`
              );
            const response = await fetch(`/api/get-subscription-details/${member.sub_id}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
              },
            });
      
            if (!response.ok) {
              const errorMessage = await response.text();
              console.error(
                `Failed to fetch subscription details for member ${member.membership_number}:`,
                errorMessage
              );
              setProgressMessage(
                `Error fetching subscription details for member ${member.membership_number} (${member.name}).`
              );
              //continue; // Skip to the next member
            }
      
            const data = await response.json();
            const subscriptionData = data.payload;
      
            // Process the subscription data to get updated member data
            const updatedMemberData = processSubscriptionData(subscriptionData, member);
            console.log('Updated Member Data after processing sub:', updatedMemberData);
            // Fetch Visits Data
            const visitsResponse = await fetch(
              `/api/get-visits?membership_numbers=${member.membership_number}`,
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json; charset=UTF-8',
                  'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                },
              }
            );
      
            if (!visitsResponse.ok) {
              const errorMessage = await visitsResponse.text();
              console.error(`Failed to fetch visits for member ${member.membership_number}:`, errorMessage);
              setProgressMessage(
                `Error fetching visits for member ${member.membership_number} (${member.name}).`
              );
              //continue; // Skip to the next member
            }
      
            const visitsData = await visitsResponse.json();
      
            // Process Visits Data
            const totalVisits = visitsData.length;
            const lastVisitDate = visitsData.length > 0 ? visitsData[0].visit_timestamp : null;
      
            // Update the member data with visits info
            updatedMemberData.visits = totalVisits;
            updatedMemberData.last_visit = lastVisitDate;
      
            // Merge the updated data with the existing member data
            const updatedMember = {
              ...member,
              ...updatedMemberData,
            };
            console.log('Updated Member after visits and merge:', updatedMember);
            // Prepare the member data for saving
            const memberToSave = {
              ...updatedMember,
              due_date: formatDateForSave(updatedMember.due_date),
              signup_date: formatDateForSave(updatedMember.signup_date),
              last_visit: formatDateForSave(updatedMember.last_visit),
              valid_until: formatDateForSave(updatedMember.valid_until),
            };
            console.log('Member to save after formatting:', memberToSave);
            // Save the updated member data to the backend
            const saveResponse = await fetch(
              `/api/member/${member.membership_number}`,
              {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json; charset=UTF-8',
                  'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(memberToSave),
              }
            );
      
            if (!saveResponse.ok) {
              console.error(`Failed to save member ${member.membership_number}`);
              setProgressMessage(
                `Error saving member ${member.membership_number} (${member.name}).`
              );
              continue; // Skip to the next member
            }
      
            const savedMember = await saveResponse.json();
      
            // Update the member in the state
            setMembers((prevMembers) =>
              prevMembers.map((m) =>
                m.membership_number === member.membership_number ? savedMember : m
              )
            );
            setProgressMessage(
                `Successfully updated member ${member.membership_number} (${member.name}). (${processedCount} of ${totalMembers})`
              );
          } catch (error) {
            console.error(`Error processing member ${member.membership_number}:`, error);
            continue; // Skip to the next member
          }
        }
        setProgressMessage('Finished refreshing and saving all members.');
        setIsRefreshingAll(false);
      };
      
      

    const processSubscriptionData = (subscriptionData, member) => {

        console.log('Subscription data:', subscriptionData);
        if(!subscriptionData || subscriptionData.length === 0) {
          console.log('No subscription data');
          return {};
        }

        const updatedData = {};
      
        const now = new Date();

        // Initialize variables
        let payments_remaining = null;
        let payment_amount = null;
        let next_payment = null;
        
        // Find the last successful billing attempt
        const successfulAttempts = subscriptionData.billing_attempts.filter(
          (attempt) => attempt.status === "completed"
        );
      

        const lastSuccessAttempt = successfulAttempts
          .sort((a, b) => new Date(b.date) - new Date(a.date))[0];
      
        // If there are no successful payments, use the signup date
        const lastPaymentDate = lastSuccessAttempt
          ? new Date(lastSuccessAttempt.date)
          : new Date(subscriptionData.order_placed);

      
        // Calculate valid_until
        let valid_until = new Date(lastPaymentDate);
      
        if (subscriptionData.billing_interval.includes("month")) {
          valid_until.setMonth(valid_until.getMonth() + 1);
        } else if (subscriptionData.billing_interval.includes("year")) {
          valid_until.setFullYear(valid_until.getFullYear() + 1);
        }
    
      
        // Calculate total_paid
        let total_paid = 0;
      
        successfulAttempts.forEach((attempt, index) => {
          // Determine the price for this billing cycle
          let cyclePrice = null;
          const cycleNumber = index + 1;
      
          if (subscriptionData.items[0]?.cycle_discounts) {
            const cycleDiscounts = subscriptionData.items[0].cycle_discounts;
      
            // Find the applicable cycle discount
            for (let i = cycleDiscounts.length - 1; i >= 0; i--) {
              if (cycleNumber >= cycleDiscounts[i].after_cycle + 1) {
                cyclePrice = parseFloat(cycleDiscounts[i].computed_price);
                break;
              }
            }
          }
      
          // If no cycle discount found, use the item's final price
          if (cyclePrice === null) {
            cyclePrice = parseFloat(subscriptionData.items[0]?.final_price) || 0;
          }
      
          total_paid += cyclePrice;
        });
      
        total_paid = Math.round(total_paid * 100) / 100; // Round to 2 decimal places
      
        // Calculate payments_remaining
        const billingMinCycles = parseInt(subscriptionData.billing_min_cycles) || 0;
        const successfulPayments = successfulAttempts.length;
      
        if (billingMinCycles > 0) {
          payments_remaining = billingMinCycles - successfulPayments;
        } else {
          payments_remaining = null;
        }
      
        // Determine next_payment
        const upcomingAttempts = subscriptionData.billing_attempts.filter(
          (attempt) => new Date(attempt.date) > now && attempt.status === ""
        );
      
        const nextAttempt = upcomingAttempts.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        )[0];
      
        next_payment = nextAttempt ? new Date(nextAttempt.date).toISOString() : null;
      
        // Payment amount (next expected payment)
        const taxRate = 1.0825; // Adjust if necessary
        payment_amount = (subscriptionData.total_value * taxRate).toFixed(2);
      
        // Signup date
        const signup_date = new Date(subscriptionData.order_placed).toISOString();
      
        // Extract email and billing address
        const email = subscriptionData.email || member.email;
      
        const address_line_1 = [subscriptionData.b_address1 || subscriptionData.s_address1, subscriptionData.b_address2 || subscriptionData.s_address2]
          .filter(Boolean) // Remove undefined or empty strings
          .join(' ');
      
        const city_state_zip = [
          subscriptionData.b_city || subscriptionData.s_city,
          subscriptionData.b_province || subscriptionData.s_province,
          subscriptionData.b_zip || subscriptionData.s_zip,
        ]
          .filter(Boolean)
          .join(', ');
      
        // Update updatedData with calculated and extracted values
        updatedData.valid_until = valid_until.toISOString();
        updatedData.signup_date = signup_date;
        updatedData.payments_remaining = payments_remaining;
        updatedData.payment_amount = payment_amount;
        updatedData.due_date= next_payment;
        updatedData.total_paid = total_paid;
        updatedData.email = email;
        updatedData.address_line_1 = address_line_1 || member.address_line_1;
        updatedData.city_state_zip = city_state_zip || member.city_state_zip;
        updatedData.term = subscriptionData.delivery_interval;
        updatedData.status = subscriptionData.status;
        updatedData.customer_id = subscriptionData.customer_id;
      
        return updatedData;
      };
      



    // Format dates for input fields
    const formatForInputDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const isoString = date.toISOString();
        return isoString.split('T')[0]; // 'YYYY-MM-DD'
    };

    // Apply filters to the members
    const applyFilters = () => {
        let filtered = [...members];
      
        // Filter by name (case-insensitive)
        if (nameFilter.trim() !== '') {
          const searchTerm = nameFilter.trim().toLowerCase();
          filtered = filtered.filter(
            (member) => member.name && member.name.toLowerCase().includes(searchTerm)
          );
        }
      
        // Filter by sub_id
        if (onlyWithSubId) {
          filtered = filtered.filter((member) => member.sub_id);
        }
      
        // Sort by selected field
        if (sortField) {
          filtered.sort((a, b) => {
            let fieldA = a[sortField];
            let fieldB = b[sortField];
      
            // Handle null or undefined values
            if (fieldA == null) return 1;
            if (fieldB == null) return -1;
      
            // Handle date fields
            const dateFields = [
              'dob',
              'due_date',
              'contract_end_date',
              'contract_start_date',
              'signup_date',
              'last_visit',
              'valid_until',
            ];
      
            if (dateFields.includes(sortField)) {
              fieldA = new Date(fieldA);
              fieldB = new Date(fieldB);
              return fieldA - fieldB;
            } else if (typeof fieldA === 'string') {
              return fieldA.localeCompare(fieldB);
            } else if (typeof fieldA === 'number') {
              return fieldA - fieldB;
            } else {
              return 0;
            }
          });
        }

        if(sortDirection === 'desc') {
            filtered = filtered.reverse();
        }
      
        setFilteredMembers(filtered);
      };
      

    // Handle edit button click
    const handleEditClick = (member) => {
        setEditingMembershipNumber(member.membership_number);


        setEditedMember({
            ...member,
            dob: formatForInputDate(member.dob),
            due_date: formatForInputDate(member.due_date),
            signup_date: formatForInputDate(member.signup_date),
            last_visit: formatForInputDate(member.last_visit),
            valid_until: formatForInputDate(member.valid_until),
        });
    };

    // Handle cancel button click
    const handleCancelClick = () => {
        setEditingMembershipNumber(null);
        setEditedMember({});
    };

    // Handle input changes in the editable row
    const handleInputChange = (e, field) => {
        let value;
        if (e.target.type === 'checkbox') {
            value = e.target.checked;
        } else if (e.target.type === 'number') {
            value = parseFloat(e.target.value);
        } else if (e.target.type === 'date') {
            value = e.target.value;
        } else {
            value = e.target.value;
        }
        setEditedMember({
            ...editedMember,
            [field]: value,
        });
        console.log('updatedMember: ', editedMember);
    };
    const formatDateForSave = (dateString) => {
        if (!dateString) return null;
        const dateStringWithT = dateString.includes('T') ? dateString : dateString + 'T00:00:00';
        const date = new Date(dateStringWithT);
        if (isNaN(date.getTime())) {
          // Invalid date
          return null;
        }
        return date.toISOString();
      };

    // Handle save button click
    const handleSaveClick = async () => {

          

        try {
            console.log('editedMember: ', editedMember);
            const memberToSave = {
                ...editedMember,
                // Use the helper function to format date fields
                dob: formatDateForSave(editedMember.dob),
                due_date: formatDateForSave(editedMember.due_date),
                signup_date: formatDateForSave(editedMember.signup_date),
                last_visit: formatDateForSave(editedMember.last_visit),
                valid_until: formatDateForSave(editedMember.valid_until),
              };

            console.log('memberToSave: ', memberToSave);
            const response = await fetch(
                `/api/member/${editingMembershipNumber}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    },
                    body: JSON.stringify(memberToSave),
                }
            );

            if (!response.ok) {
                console.error('Failed to save member');
                return;
            }

            const updatedMember = await response.json();

            // Update the member in the state
            setMembers(
                members.map((member) =>
                    member.membership_number === editingMembershipNumber
                        ? updatedMember
                        : member
                )
            );
            setEditingMembershipNumber(null);
            setEditedMember({});
        } catch (error) {
            console.error('Error saving member:', error);
        }
    };

    // Format date fields
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };

    return (
        <div className="table-container">
            <h1>Members Management</h1>
            {progressMessage && <div className="progress-message">{progressMessage}</div>}

            {/* Filter Controls */}
            <div className="filter-container">
                <div className="filter-item">
                    <label htmlFor="nameFilter">Search Name:</label>
                    <input
                        type="text"
                        id="nameFilter"
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        placeholder="Enter name..."
                    />
                </div>
                <div className="filter-item">
                <label>
                    <input
                    type="checkbox"
                    checked={onlyWithSubId}
                    onChange={(e) => setOnlyWithSubId(e.target.checked)}
                    />
                    Show only members with Sub ID
                </label>
                </div>
                <div className="filter-item">
                <label htmlFor="sortField">Sort By:</label>
                <select
                    id="sortField"
                    value={sortField}
                    onChange={(e) => setSortField(e.target.value)}
                >
                    <option value="name">Name</option>
                    <option value="membership_number">Membership Number</option>
                    <option value="valid_until">Valid Until</option>
                    <option value="due_date">Due Date</option>
                    <option value="visits">Visits</option>
                    {/* Add more fields as needed */}
                </select>
                <select
                    id="sortDirection"
                    value={sortDirection}
                    onChange={(e) => setSortDirection(e.target.value)}
                >
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                    {/* Add more fields as needed */}
                </select>
                </div>
                <div className="filter-item">
                <button
                    className="refresh-all-button"
                    onClick={handleRefreshAndSaveAll}
                    disabled={isRefreshingAll}
                >
                    {isRefreshingAll ? 'Refreshing...' : 'Refresh and Save All'}
                </button>
                </div>



            </div>

            <table className="members-table">
                <thead>
                    <tr>
                        {/* Table Headers */}
                        <th>Actions</th>

                        <th>Membership Number</th>
                        <th>Name</th>
                        <th>Membership Type</th>
                        <th>Status</th>
                        <th>Term</th>
                        <th>Due Date</th>
                        <th>Signup Date</th>
                        <th>Visits</th>
                        <th>DOB</th>
                        <th>Age</th>
                        <th>Last Visit</th>
                        <th>Address Line 1</th>
                        <th>City, State, ZIP</th>
                        <th>Comment</th>
                        <th>Alert</th>
                        <th>Email</th>
                        <th>Barcode</th>
                        <th>Valid Until</th>
                        <th>Sub ID</th>
                        <th>Customer ID</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredMembers.map((member) => {
                        const isEditing =
                            member.membership_number === editingMembershipNumber;
                        return (
                            <tr
                                key={member.membership_number}
                                className={isEditing ? 'editing-row' : ''}
                            >
                                {isEditing ? (
                                    <>
                                        {/* Editable Fields */}
                                        <td>
                                            <button
                                                className="save-button"
                                                onClick={handleSaveClick}
                                            >
                                                Save
                                            </button>
                                            <button
                                                className="cancel-button"
                                                onClick={handleCancelClick}
                                            >
                                                Cancel
                                            </button>
                                        </td>
                                        <td>{member.membership_number}</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editedMember.name || ''}
                                                onChange={(e) => handleInputChange(e, 'name')}
                                                className={changedFields.includes('name') ? 'changed-field' : ''}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editedMember.membership_type || ''}
                                                onChange={(e) =>
                                                    handleInputChange(e, 'membership_type')
                                                }
                                                className={changedFields.includes('membership_type') ? 'changed-field' : ''}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editedMember.status || ''}
                                                onChange={(e) => handleInputChange(e, 'status')}
                                                className={changedFields.includes('status') ? 'changed-field' : ''}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editedMember.term || ''}
                                                onChange={(e) => handleInputChange(e, 'term')}
                                                className={changedFields.includes('term') ? 'changed-field' : ''}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="date"
                                                value={editedMember.due_date || ''}
                                                onChange={(e) => handleInputChange(e, 'due_date')}
                                                className={changedFields.includes('due_date') ? 'changed-field' : ''}
                                            />
                                        </td>
                                
                                        <td>
                                            <input
                                                type="date"
                                                value={editedMember.signup_date || ''}
                                                onChange={(e) =>
                                                    handleInputChange(e, 'signup_date')
                                                }
                                                className={changedFields.includes('signup_date') ? 'changed-field' : ''}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                value={editedMember.visits || 0}
                                                onChange={(e) => handleInputChange(e, 'visits')}
                                                className={changedFields.includes('visits') ? 'changed-field' : ''}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="date"
                                                value={editedMember.dob || ''}
                                                onChange={(e) => handleInputChange(e, 'dob')}
                                                className={changedFields.includes('dob') ? 'changed-field' : ''}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                value={editedMember.age || ''}
                                                onChange={(e) => handleInputChange(e, 'age')}
                                                className={changedFields.includes('age') ? 'changed-field' : ''}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="date"
                                                value={editedMember.last_visit || ''}
                                                onChange={(e) => handleInputChange(e, 'last_visit')}
                                                className={changedFields.includes('last_visit') ? 'changed-field' : ''}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editedMember.address_line_1 || ''}
                                                onChange={(e) =>
                                                    handleInputChange(e, 'address_line_1')
                                                }
                                                className={changedFields.includes('address_line_1') ? 'changed-field' : ''}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editedMember.city_state_zip || ''}
                                                onChange={(e) =>
                                                    handleInputChange(e, 'city_state_zip')
                                                }
                                                className={changedFields.includes('city_state_zip') ? 'changed-field' : ''}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editedMember.comment || ''}
                                                onChange={(e) => handleInputChange(e, 'comment')}
                                                className={changedFields.includes('comment') ? 'changed-field' : ''}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editedMember.alert || ''}
                                                onChange={(e) => handleInputChange(e, 'alert')}
                                                className={changedFields.includes('alert') ? 'changed-field' : ''}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                type="email"
                                                value={editedMember.email || ''}
                                                onChange={(e) => handleInputChange(e, 'email')}
                                                className={changedFields.includes('email') ? 'changed-field' : ''}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editedMember.barcode || ''}
                                                onChange={(e) => handleInputChange(e, 'barcode')}
                                                className={changedFields.includes('barcode') ? 'changed-field' : ''}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="date"
                                                value={editedMember.valid_until || ''}
                                                onChange={(e) =>
                                                    handleInputChange(e, 'valid_until')
                                                }
                                                className={changedFields.includes('valid_until') ? 'changed-field' : ''}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editedMember.sub_id || ''}
                                                onChange={(e) => handleInputChange(e, 'sub_id')}
                                                className={changedFields.includes('sub_id') ? 'changed-field' : ''}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editedMember.customer_id || ''}
                                                onChange={(e) =>
                                                    handleInputChange(e, 'customer_id')
                                                }
                                                className={changedFields.includes('customer_id') ? 'changed-field' : ''}
                                            />
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        {/* Read-Only Fields */}
                                        <td>
                                            <button
                                                className="refresh-button"
                                                onClick={() => handleEditClick(member)}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                className="refresh-button"
                                                onClick={() => handleRefreshMember(member)}
                                            >
                                                Refresh
                                            </button>
                                        </td>
                                        <td>{member.membership_number}</td>
                                        <td>{member.name}</td>
                                        <td>{member.membership_type}</td>
                                        <td>{member.status}</td>
                                        <td>{member.term}</td>
                                        <td>{formatDate(member.due_date)}</td>
                                        <td>{formatDate(member.signup_date)}</td>
                                        <td>{member.visits}</td>
                                        <td>{formatDate(member.dob)}</td>
                                        <td>{member.age}</td>
                                        <td>{formatDate(member.last_visit)}</td>
                                        <td>{member.address_line_1}</td>
                                        <td>{member.city_state_zip}</td>
                                        <td>{member.comment}</td>
                                        <td>{member.alert}</td>
                                        <td>{member.email}</td>
                                        <td>{member.barcode}</td>
                                        <td>{formatDate(member.valid_until)}</td>
                                        <td>{member.sub_id}</td>
                                        <td>{member.customer_id}</td>

                                    </>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default MembersTable;
